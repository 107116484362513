<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Activity
        </b-card-title>
        <b-card-sub-title>Monthly Statistics</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="area"
        height="400"
        :options="chartOptions"
        :series="activities"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'
import axios from '@/libs/axios' // eslint-disable-line

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      apexChatData,
      chartOptions: {},
      activities: [],
    }
  },
  mounted() {
    this.getDataChart()
  },
  methods: {
    async getDataChart() {
      const response = await axios.get('/api/chart/revenue_activities_data')
      if (response.status === 200) {
        const data = response.data[0]

        const leadData = []
        const registeredData = []
        const trialData = []
        const canceledData = []
        const subscribedData = []
        const categories = []

        for (const item of data) { // eslint-disable-line
          leadData.push(item.lead)
          registeredData.push(item.registered)
          canceledData.push(item.canceled)
          subscribedData.push(item.subscribed)
          trialData.push(item.trial)
          categories.push(item.month)
        }
        this.chartOptions = this.apexChatData.lineAreaChartSpline.vaChartOption
        this.chartOptions.xaxis.categories = categories

        const lead = {
          name: 'Lead',
          data: leadData,
        }

        const registered = {
          name: 'Registered',
          data: registeredData,
        }

        const canceled = {
          name: 'Canceled',
          data: canceledData,
        }

        const subscribed = {
          name: 'Subscribed',
          data: subscribedData,
        }

        const trial = {
          name: 'Trial',
          data: trialData,
        }

        this.activities.push(lead)
        this.activities.push(registered)
        this.activities.push(trial)
        this.activities.push(subscribed)
        this.activities.push(canceled)
      }
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
