<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Revenue
        </b-card-title>
        <b-card-sub-title>Monthly Statistics</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="area"
        height="400"
        :options="chartOptions"
        :series="activities"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'
import axios from '@/libs/axios' // eslint-disable-line

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      apexChatData,
      chartOptions: {},
      activities: [],
    }
  },
  mounted() {
    this.getDataChart()
  },
  methods: {
    async getDataChart() {
      const response = await axios.get('/api/chart/revenue_activities_data')
      if (response.status === 200) {
        const data = response.data[1]

        const incomeData = []
        const categories = []

        for (const item of data) { // eslint-disable-line
          incomeData.push(item.income)
          categories.push(item.month)
        }
        this.chartOptions = this.apexChatData.lineAreaChartSpline.vaChartOption
        this.chartOptions.xaxis.categories = categories

        const revenue = {
          name: 'Revenue',
          data: incomeData,
        }
        this.activities.push(revenue)
      }
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
