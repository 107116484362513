var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[(_vm.rows)?_c('vue-good-table',{ref:"AffHomeTable",staticClass:"mt-3",attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
      enabled: true,
      placeholder: 'Search this table',
    },"sort-options":{
      enabled: true,
      initialSortBy: {field: 'subscribed_date', type: 'desc'}
    },"pagination-options":{
      enabled: true,
      perPage: 10,
      perPageDropdown: [10]
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'subscribed_date')?_c('span',[_c('b',[_vm._v(_vm._s(_vm.formatDate(props.row.subscribed_date)))])]):(props.column.field === 'subscriber_status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.subscriber_status)}},[_vm._v(" "+_vm._s(props.row.subscriber_status)+" ")])],1):(props.column.field === 'referral_status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant2(props.row.referral_status)}},[_vm._v(" "+_vm._s(props.row.referral_status)+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2139646459)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }