<template>
  <div
    v-if="!loaded"
    class="d-flex spinner-border text-primary justify-content-center"
    style="width: 2.7rem; height: 2.7rem; margin: 5em auto 0 auto;"
    role="status"
  />
  <div v-else>
    <AffiliateJoin v-if="affiliate === false" />
    <affiliate-home v-else />
  </div>
</template>

<script>

import AffiliateHome from '../home/AffiliateHome.vue'
import AffiliateJoin from './AffiliateJoin.vue'
import axios from '@/libs/axios' // eslint-disable-line

export default {
  components: {
    AffiliateHome,
    AffiliateJoin,
  },

  data() {
    return {
      affiliate: false,
      loaded: false,
    }
  },

  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },

  created() {
    this.isAffiliate()
  },

  methods: {
    isAffiliate() {
      axios.get('/api/affiliates/is_affiliate')
        .then(res => {
          const test = Boolean(res.data.is_affiliate)
          this.affiliate = test
        })

      setTimeout(() => {
        this.loaded = true
      }, 1200)
    },
  },
}
</script>
