<template>
  <div
    class="home-wrapper"
  >
    <b-row v-if="userID!==0">
      <b-col sm="12">
        <b-card>
          <b-row>
            <b-col>
              Begin earning revenue by promoting our platform. For each new client who registers using your referral link,
              you will receive a 10%  revenue share during their first year, followed by a 3% lifetime revenue share on all their subsequent purchases.
              Alternatively, if you promote the CPA referral link, you can receive a fixed CPA payout ranging between €200 and €400,
              depending on the subscription plan chosen by the referred client. This presents a lucrative opportunity for you
              to generate income while supporting our platform's growth.
              <br><br>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group>
                <label
                  for="basic-password"
                  class="font-weight-bold"
                >YOUR REFERRAL LINK (REVENUE-SHARE):</label>
                <b-input-group>
                  <b-form-input
                    id="revenue-link"
                    v-model="revenueShareLink"
                    disabled=""
                  />
                  <b-input-group-append>
                    <b-button
                      v-clipboard:copy="revenueShareLink"
                      v-clipboard:success="onCopy"
                      variant="outline-primary"
                    >
                      Copy
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group>
                <label
                  for="basic-password"
                  class="font-weight-bold"
                >YOUR REFERRAL LINK (CPA):</label>
                <b-input-group>
                  <b-form-input
                    id="revenue-link"
                    v-model="referralLink"
                    disabled=""
                  />
                  <b-input-group-append>
                    <b-button
                      v-clipboard:copy="referralLink"
                      v-clipboard:success="onCopy"
                      variant="outline-primary"
                    >
                      Copy
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="primary"
          :statistic="leads"
          statistic-title="Leads"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="registered"
          statistic-title="Registered"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ClockIcon"
          color="info"
          :statistic="pending"
          statistic-title="Pending Users"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="warning"
          :statistic="trial"
          statistic-title="Trial Users"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserCheckIcon"
          color="success"
          :statistic="subscribed"
          statistic-title="Subscribed"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserXIcon"
          color="danger"
          :statistic="expired"
          statistic-title="Expired Users"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserXIcon"
          color="danger"
          :statistic="canceled"
          statistic-title="Canceled Users"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="total"
          statistic-title="Total Users"
        />
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <b-col
        sm="6"
      >
        <activity />
      </b-col>
      <b-col
        sm="6"
      >
        <revenue />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <b-card no-body>
          <b-tabs card>
            <b-tab
              title="My Revenue Shares"
              active
            >
              <affiliate-revenue-detail
                :record-type="1"
                class="table-revenue"
              />
            </b-tab>
            <b-tab
              title="Paid Revenue Shares"
            >
              <affiliate-revenue-detail
                :record-type="2"
                class="table-revenue"
              />
            </b-tab>
            <b-tab
              title="My Subscribers"
            >
              <affiliate-subscribers class="table-subcribers" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormInput,
  BButton, BFormGroup, BTabs, BTab,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@/libs/axios' // eslint-disable-line
import Revenue from './chart/Revenue.vue'
import Activity from './chart/Activity.vue'
import AffiliateRevenueDetail from '../affiliate/AffiliateRevenueDetail.vue'
import AffiliateSubscribers from '../affiliate/AffiliateSubscribers.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    StatisticCardHorizontal,
    Activity,
    Revenue,
    BFormGroup,
    BTabs,
    BTab,
    AffiliateRevenueDetail,
    AffiliateSubscribers,
  },

  data() {
    return {
      revenueShareLink: 'https://www.trafficmanager.com/r/',
      referralLink: 'https://www.trafficmanager.com/cpa/',
      subscribed: 0,
      trial: 0,
      pending: 0,
      registered: 0,
      leads: 0,
      canceled: 0,
      expired: 0,
      total: 0,
      pageLength: 25,
      makepdf: false,
      userID: 0,
    }
  },
  computed: {
    registeredAffiliateStat() {
      return this.$store.state.affiliate.registeredAffiliateStat
    },
  },
  created() {
    this.fetchRegisteredAffiliateStat()
  },
  mounted() {
    const refreshId = setInterval(() => {
      const data = this.registeredAffiliateStat
      if (data) {
        this.getAffiliateStat(data)
        clearInterval(refreshId)
      }
    }, 1000)
  },
  methods: {

    formatDate2(date) {
      const datum = new Date(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Intl.DateTimeFormat('en-US', options).format(datum)
    },

    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },

    getAffiliateStat(data) {
      this.rows = data.users
      this.userID = data.user.uid
      this.subscribed = Number(data.subscribed)
      this.trial = Number(data.trial)
      this.pending = Number(data.pending)
      this.registered = Number(data.registered)
      this.leads = Number(data.leads)
      this.canceled = Number(data.canceled)
      this.expired = Number(data.expired)
      this.total = this.subscribed + this.trial + this.pending + this.registered + this.leads + this.canceled + this.expired

      this.revenueShareLink += this.userID
      this.referralLink += this.userID
    },
    fetchRegisteredAffiliateStat() {
      this.$store.dispatch('affiliate/fetchRegisteredAffiliateStat')
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Link Copied',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style scoped>
.home-wrapper div.col-sm-6.col-lg-3 a {
  color: #6e6b7b;
}

.aff-h6{
  max-width: 87rem;
  font-size: 16px;
  margin: 0 auto 28px;
  font-weight: bold;
  line-height: 21px;
  color: #8b8b8b;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 28px;
}
</style>

<style>

.activation-btn {
  width: 100%;
  max-width: 16rem;
}
.slot {
  padding: 0.5rem;
  justify-content: space-between;
}
.slot span{
  margin-right:5px; margin-top:5px;
  font-size: 11px;
  font-weight: bold;
}
.table-revenue, .table-subcribers { margin-top: -40px !important;}

.dark-layout .vgt-wrap__footer {
  border: 1px solid #3d4354;
}
.dark-layout .vgt-global-search{
  border: 0px;
}

.dark-layout .nav-link.active {
 color: #fb50a1 !important;
}
</style>
