<template>
  <b-card>
    <!-- table -->
    <vue-good-table
      v-if="rows"
      ref="AffHomeTable"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        placeholder: 'Search this table',
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: {field: 'subscribed_date', type: 'desc'}
      }"
      :pagination-options="{
        enabled: true,
        perPage: 10,
        perPageDropdown: [10]
      }"
      class="mt-3"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: subscribed_date -->
        <span v-if="props.column.field === 'subscribed_date'">
          <b>{{ formatDate(props.row.subscribed_date) }}</b>
        </span>

        <!-- Column: subscriber_status -->
        <span v-else-if="props.column.field === 'subscriber_status'">
          <b-badge :variant="statusVariant(props.row.subscriber_status)">
            {{ props.row.subscriber_status }}
          </b-badge>
        </span>

        <!-- Column: subscriber_status -->
        <span v-else-if="props.column.field === 'referral_status'">
          <b-badge :variant="statusVariant2(props.row.referral_status)">
            {{ props.row.referral_status }}
          </b-badge>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>

import { BCard, BButton, BSpinner, BBadge} from 'bootstrap-vue' // eslint-disable-line
import { VueGoodTable } from 'vue-good-table'
import axios from '@/libs/axios' // eslint-disable-line
import moment from 'moment'

export default {
  components: {
    BCard, BButton, BSpinner, // eslint-disable-line
    VueGoodTable,
    BBadge,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Subscribers',
          field: 'subscriber_name',
        },
        {
          label: 'Subscriber\'s Status',
          field: 'subscriber_status',
        },
        {
          label: 'Package',
          field: 'package',
        },
        {
          label: 'Subscribed Date',
          field: 'subscribed_date',
          sortable: true,
          sortFn: this.sortFnSubscribedDate,
        },
        {
          label: 'Referral Type',
          field: 'referral_type_text',
        },
        {
          label: 'Referral Commission Status',
          field: 'referral_status',
        },
      ],
      rows: [],
      makepdf: false,
      statDates: {
        startDate: null,
        endDate: null,
      },
      ranges: {},
      getNewStatsSpinner: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Subscribed : 'light-success',
        Trial   : 'light-primary',
        Lead   : 'light-info',
        Registered   : 'light-success',
        Pending   : 'light-danger',
        Canceled   : 'light-danger',
        Expired   : 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  created() {
    this.fetchRecord()
  },
  methods: {

    async fetchRecord() {
      const records = await axios.get('/api/affiliates/get_affiliate_subscribers')
      this.rows = records.data
    },

    formatDate(datum) {
      return moment(new Date(datum)).format('DD MMM YYYY')
    },

    sortFnSubscribedDate(x, y, col, rowX, rowY) { // eslint-disable-line
      // get x
      let value1 = 0
      value1 = Date.parse(this.filterFormatDate(rowX.subscribed_date))

      // get y
      let value2 = 0
      value2 = Date.parse(this.filterFormatDate(rowY.subscribed_date))

      // test values
        return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },

    statusVariant2(type) {
      if (type === 'Active') return 'light-success'
      return 'light-danger'
    },
  },
}
</script>
<style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
.activation-btn {
  width: 100%;
  max-width: 16rem;
}
.slot {
  padding: 0.5rem;
  justify-content: space-between;
}
.slot span{
  margin-right:5px; margin-top:5px;
  font-size: 11px;
  font-weight: bold;
}
.mt-3 table tr th{font-size: 11px;}
.mt-3 table tr td{font-size: 12px;}
.mt-3 { margin-top: 0px !important;}
.stat-picker{
  margin-right: 0.3rem;
  margin-bottom: -10px !important;
}
.btn-action{
  margin-top: 5px !important;
  float: right;
}
/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 430px)  {
  [dir] .card-body{
    padding: 1rem;
  }
}
</style>
