<template>
  <b-card>
    <p style="padding:20px">
      Start making money by promoting our platform. For each client that will register with your referral link, you
      will receive a monthly revenue share of 10% for one year on all their purchases or, if you promote the
      CPA referral link a fixed CPA payout between 200 and 400 euros, depending on the subscribed plan.
    </p>
    <p style="padding:20px">
      <b-button
        variant="primary"
        :disabled="sending"
        @click="joinTheProgram"
      >
        <span v-if="sending">
          <b-spinner
            small
            type="grow"
          />
          Joining...
        </span>
        <span v-else>Join the program</span>
      </b-button>
    </p>
  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BCard, BButton, BSpinner,
} from 'bootstrap-vue'
import axios from '@/libs/axios' // eslint-disable-line

export default {

  components: {
    BCard, BButton, BSpinner,
  },

  data() {
    return {
      sending: false,
    }
  },

  methods: {
    async joinTheProgram() {
      this.sending = false
      await axios.post('/api/affiliates/join_the_program')
        .then(() => {
          this.$router.go(0)
        })
      this.sending = true
    },
  },
}
</script>
