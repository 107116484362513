<template>
  <b-card>
    <h3 v-if="isAdmin===1">
      Amount Request Details (total: € {{ totalRevenue }})
    </h3>
    <hr v-if="isAdmin===1">
    <!-- table -->
    <vue-good-table
      v-if="rows"
      ref="AffHomeTable"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: showSearchbox,
        placeholder: 'Search this table',
        searchFn: searchRegisteredUser,
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: {field: 'invoice_paid_date', type: 'desc'}
      }"
      :pagination-options="{
        enabled: true,
        perPage: 10,
        perPageDropdown: [10]
      }"
      class="mt-3 revdetail"
    >
      <div
        v-if="showAction"
        slot="table-actions"
        style="border:0px solid #fff;position:relative;"
      >
        <div>
          <date-range-picker
            ref="picker"
            v-model="statDates"
            class="stat-picker"
            style="width: auto;margin-bottom: 15px;"
            opens="left"
            :ranges="ranges"
            @update="getNewStats"
          >
            <div
              slot="input"
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
                class="stat-calendar"
              />
            </div>
            <div
              slot="footer"
              slot-scope="data"
              class="slot"
            >
              <div class="custom-search d-flex justify-content-end">
                <!-- <span>Date Range:</span> <span>{{ data.rangeText }}</span> -->
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="cancelResetSearch(data)"
                >
                  Reset Search
                </b-button>&nbsp;
                <b-button
                  size="sm"
                  variant="primary"
                  @click="data.clickApply"
                >
                  Apply
                </b-button>
              </div>
            </div>
          </date-range-picker>
          <b-button
            class="btnPrint"
            variant="outline-primary"
            size="sm"
            @click="printList"
          >
            Print
          </b-button>
        </div>
        <div class="adjustments">
          <b-button
            class="btnDownload"
            variant="outline-primary"
            size="sm"
            style="margin-right:5px;height: 32px;"
            @click="downloadPDF"
          >
            <span v-if="makepdf">
              <b-spinner
                small
                type="grow"
              />
              Creating...
            </span>
            <span v-else>PDF</span>
          </b-button>
          <b-button
            class="btnCSV"
            variant="outline-primary"
            size="sm"
            style="margin-right:5px;height: 32px;"
            @click="downloadCSV"
          >
            CSV
          </b-button>
        </div>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: invoice_id -->
        <span v-if="props.column.field === 'invoice_id' && isAdmin===1">
          <center><router-link :to="`/invoices/${props.row.invoice_id}`">{{ props.row.invoice_id }}</router-link></center>
        </span>

        <!-- Column: subscriber_name -->
        <span v-else-if="props.column.field === 'subscriber_name' && isAdmin===1">
          <router-link :to="`/user/details/${props.row.subscriber_uid}`">{{ props.row.subscriber_name }}</router-link>
        </span>

        <!-- Column: subscribed_date -->
        <span v-else-if="props.column.field === 'subscribed_date'">
          {{ formatDate(props.row.subscribed_date) }}
        </span>

        <!-- Column: invoice_created_date -->
        <span v-else-if="props.column.field === 'invoice_created_date'">
          {{ formatDate(props.row.invoice_created_date) }}
        </span>

        <!-- Column: invoice_paid_date -->
        <span v-else-if="props.column.field === 'invoice_paid_date'">
          <b>{{ formatDate(props.row.invoice_paid_date) }}</b>
        </span>

        <!-- Column: revenue_share -->
        <span
          v-else-if="props.column.field === 'revenue_share'"
          class="text-success"
        >
          <b> € {{ props.row.revenue_share }} </b>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>

import { BCard, BButton, BSpinner} from 'bootstrap-vue' // eslint-disable-line
import { VueGoodTable } from 'vue-good-table'
import axios from '@/libs/axios' // eslint-disable-line
import moment from 'moment'
import VueHtml2Canvas from 'vue-html2canvas'
import jsPDF from 'jspdf'

import Vue from 'vue'
import Clipboard from 'v-clipboard'

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

Vue.use(VueHtml2Canvas)
Vue.use(Clipboard)

export default {
  components: {
    BCard, BButton, BSpinner, // eslint-disable-line
    VueGoodTable,
    DateRangePicker,
  },
  filters: {
    date(date) {
      const datum = new Date(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Intl.DateTimeFormat('en-US', options).format(datum)
    },
  },
  props: {
    recordType: {
      type: Number,
      default: 1,
    },

    isAdmin: {
      type: Number,
      default: 0,
    },

    comID: {
      type: Number,
      default: 0,
    },

    showAction: {
      type: Boolean,
      default: true,
    },
    showSearchbox: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Invoice Sys ID',
          field: 'invoice_id',
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left',
        },
        {
          label: 'Subscribers',
          field: 'subscriber_name',
        },
        {
          label: 'Subscribed Date',
          field: 'subscribed_date',
          sortable: true,
          sortFn: this.sortFnRegistrationDate,
        },
        {
          label: 'Package',
          field: 'package',
        },
        {
          label: 'Invoice Date Issue',
          field: 'invoice_created_date',
          sortable: true,
          sortFn: this.sortFnInvoiceDate,
        },
        {
          label: "Subscriber's Invoice Paid Date",
          field: 'invoice_paid_date',
          sortable: true,
          sortFn: this.sortFnCustomPaydateDate,
          filterOptions: {
            enabled: false,
            filterFn: this.filterRegistration,
          },
        },
        {
          label: 'Revenue Share',
          field: 'revenue_share',
          sortFn: this.sortFnRevenueShare,
        },
      ],
      rows: [],
      makepdf: false,
      statDates: {
        startDate: null,
        endDate: null,
      },
      ranges: {},
      getNewStatsSpinner: false,
      totalRevenue: 0,
    }
  },
  created() {
    this.fetchRecord()
  },
  methods: {

    async fetchRecord() {
      let rec = null
      if (this.recordType === 1) rec = await this.fetchUserRevenues()
      else if (this.recordType === 2) rec = await this.fetchUserPaidRevenues()
      else if (this.recordType === 3) rec = await this.fetchUserRevenuesComID()

      this.rows = rec.data
      this.dataPickerRanges()
      this.getTotalRevenue()
    },

    async fetchUserRevenues() {
      const records = await axios.get('/api/affiliates/get_affiliate_revenues')
      return records
    },

    async fetchUserPaidRevenues() {
      const records = await axios.get('/api/affiliates/get_affiliate_paid_revenues')
      return records
    },

    async fetchUserRevenuesComID() {
      const records = await axios.get(`/api/affiliates/get_affiliate_revenues_by_com_id?commission_id=${this.comID}`)
      return records
    },

    async fetchUserRevenuesForAdmin() {
      const records = await axios.get('/api/affiliates/getUserRevenueForAdmin')
      return records
    },
    formatDate(datum) {
      return moment(new Date(datum)).format('DD MMM YYYY')
    },

    getTotalRevenue() {
      let sum = 0
      for (let i = 0; i < this.rows.length; i=i+1) { // eslint-disable-line
        sum += Number(this.rows[i].revenue_share)
      }
      this.totalRevenue = sum.toFixed(2)
    },

    sortFnRegistrationDate(x, y, col, rowX, rowY) { // eslint-disable-line
      // get x
      let value1 = 0
      value1 = Date.parse(this.filterFormatDate(rowX.subscribed_date))

      // get y
      let value2 = 0
      value2 = Date.parse(this.filterFormatDate(rowY.subscribed_date))

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnInvoiceDate(x, y, col, rowX, rowY) { // eslint-disable-line
      // get x
      let value1 = 0
      value1 = Date.parse(this.filterFormatDate(rowX.invoice_created_date))

      // get y
      let value2 = 0
      value2 = Date.parse(this.filterFormatDate(rowY.invoice_created_date))

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnCustomPaydateDate(x, y, col, rowX, rowY) { // eslint-disable-line

      // get x
      let value1 = 0
      value1 = Date.parse(this.filterFormatDate(rowX.invoice_paid_date))

      // get y
      let value2 = 0
      value2 = Date.parse(this.filterFormatDate(rowY.invoice_paid_date))

      // test values
        return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnRevenueShare(x, y, col, rowX, rowY) { // eslint-disable-line

      // get x
      const value1 = Number(rowX.revenue_share)

      // get y
      const value2 = Number(rowY.revenue_share)

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    async getNewStats() {
      this.getNewStatsSpinner = true
      this.showDetail = false
      this.getNewStatsSpinner = false
      this.showDetail = true

      this.$set(this.columns[4].filterOptions, 'enabled', true)
      this.$set(this.columns[4].filterOptions, 'filterValue', '')
      this.$set(this.columns[4].filterOptions, 'filterValue', this.statDates)
      this.columns[4].filterOptions.filterFn()
      this.removeExtraTableHeader()
    },
    removeExtraTableHeader() {
      const refreshId = setInterval(() => {
        if (this.$refs.AffHomeTable.$refs.table.tHead.rows.length > 1) {
          this.$refs.AffHomeTable.$refs.table.tHead.rows[1].hidden = true
          clearInterval(refreshId)
        }
      }, 100)
    },
    cancelResetSearch(data) {
      data.clickCancel() // eslint-disable-line
      this.$refs.AffHomeTable.reset()
    },

    searchRegisteredUser( row, col, cellValue, searchTerm ) {  // eslint-disable-line
      this.$set(this.columns[4].filterOptions, 'filterValue', '')
      this.$set(this.columns[4].filterOptions, 'enabled', false)
      this.$set(this.$refs.AffHomeTable.searchOptions, 'enabled', true)
      if (typeof cellValue === 'string') {
        const strToSearch = cellValue.toLowerCase()
        const param = searchTerm.toLowerCase()
        if(strToSearch.search(param)>=0) return true // eslint-disable-line
        else return false // eslint-disable-line
      } if (typeof cellValue === 'number') {
        const strToSearch = Number(cellValue)
        const param = Number(searchTerm)
        if (strToSearch === param) return true
        return false
      }
      return false
    },

    dataPickerRanges() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      const yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)

      const startDate = new Date(today.getFullYear(), today.getMonth(), 1)
      const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      const thisMonthStart = new Date(startDate)
      const thisMonthEnd = new Date(endDate)

      const last7days = new Date()
      last7days.setDate(today.getDate() - 7)
      last7days.setHours(0, 0, 0, 0)

      const ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        'Last 7 days': [last7days, today],
        'This month': [thisMonthStart, thisMonthEnd],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      }
      this.ranges = ranges
    },

    filterRegistration(data, statDates) {
      if (typeof data === 'undefined') return // eslint-disable-line
      const myDate = new Date(data)
      const paramDate = Date.parse(this.filterFormatDate(myDate))
      const startDate = Date.parse(this.filterFormatDate(statDates.startDate))
      const endDate = Date.parse(this.filterFormatDate(statDates.endDate))
      return (paramDate >= startDate && paramDate <= endDate)// eslint-disable-line
    },

    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },

    printList() {
      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */
      el = this.$refs.AffHomeTable.$refs.table

      const header = require('@/assets/images/banner/invoiceheader.png')  // eslint-disable-line
      const head = document.getElementsByTagName('head') // eslint-disable-line

      const newWindowObj = window.open('', 'MyWindow')
      newWindowObj.document.write('<html>')
      newWindowObj.document.write('<body>')
      newWindowObj.document.write(`<img src=${header}/>`)
      newWindowObj.document.write(el.outerHTML)
      newWindowObj.document.write('<style>')
      newWindowObj.document.write('th,td {text-align: center;}')
      newWindowObj.document.write('th,td { border-bottom: 1px solid #000; padding: 3px;margin: 0px;}')
      newWindowObj.document.write('th,td { border-left: 1px solid #000; padding: 3px;margin: 0px;}')
      newWindowObj.document.write('th{ background: #e8e8e8; }')
      newWindowObj.document.write('table{ width: 80%; border-spacing: 0px; margin-left: auto;')
      newWindowObj.document.write('margin-right: auto;}')
      newWindowObj.document.write('tr > td:last-of-type {border-right: 1px solid #000; padding: 3px;margin: 0px;}')
      newWindowObj.document.write('tr > th:last-of-type {border-right: 1px solid #000; padding: 3px;margin: 0px;}')
      newWindowObj.document.write('th{border-top: 1px solid #000;}')
      newWindowObj.document.write('</style>')
      newWindowObj.document.write('</body></html>')
      newWindowObj.document.close()
      newWindowObj.print()
    },

    downloadCSV() {
      const header = [
        'Subscribers',
        'Subscribed Date',
        'Package',
        'Invoice Date Issue',
        'Invoice Paid Date',
        'Revenue Share',
      ]

      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */
      el = this.$refs.AffHomeTable.$refs.table

      let hasRows = true
      const rows = []

      if (el.rows[1].outerText === 'No data for table') {
        hasRows = false
      }

      if (hasRows) {
        Object.keys(el.rows).forEach(key => {
          if (el.rows[1].outerText !== 'No data for table') {
            if (key >= 1) {
              const row = []
              row.push(el.rows[key].cells[1].textContent.trim())
              row.push(el.rows[key].cells[2].textContent.trim())
              row.push(el.rows[key].cells[3].textContent.trim())
              row.push(el.rows[key].cells[4].textContent.trim())
              row.push(el.rows[key].cells[5].textContent.trim())
              row.push(el.rows[key].cells[6].textContent.trim())
              rows.push(row)
            }
          }
        })
      }

      rows.unshift(header)
      const jsonObject = JSON.stringify(rows)
      const csv = this.convertToCSV(jsonObject)

      const exportedFilenmae = 'Payment List.csv'
      const blob = new Blob([`\ufeff${csv}`], { type: 'text/csv; charset=utf-8' })

      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', exportedFilenmae)
      link.style.visibility = 'hidden'
      link.id = 'downloadCsv'
      document.body.appendChild(link)
      document.getElementById('downloadCsv').click()

      // remove to make sure a new csv file will be downloaded and not the previous one
      const element = document.getElementById('downloadCsv')
      element.remove()
    },

    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
      let str = ''
      for (let i = 0; i < array.length; i++) { // eslint-disable-line
        let line = ''
        for (const index in array[i]) { // eslint-disable-line
          if (line !== '') line += ','
          line += array[i][index]
        }
        str += `${line}\r\n` // eslint-disable-line
      }
      return str
    },

    downloadPDF() {
      this.makepdf = true
      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */

      el = this.$refs.AffHomeTable.$refs.table
      const options = {
        type: 'dataURL',
      }
      const output = new this.$html2canvas(el, options)
      output.then(ContentCanvas => {
        // get the png invoice header
        /* eslint-disable global-require */
        const headerUrl = require('@/assets/images/banner/invoiceheader.png')
        /* eslint-enable global-require */

        this.getFileFromServer(headerUrl, invoiceDataUrl => {
          /* eslint-disable new-cap */
          const pdf = new jsPDF('p', 'px', 'a4')
          /* eslint-enable new-cap */

          pdf.addImage(invoiceDataUrl, 'png', 0, 0, 440, 100)

          const rowHeight = 20 * (el.rows.length)
          pdf.addImage(ContentCanvas, 'png', 5, 100, 1102 / 2.53, rowHeight)

          // const filename = Math.random().toString(36).substr(2, 5)
          const filename = 'RequestPaymentList'

          // create a new element
          const link = document.createElement('a')

          link.id = 'downloadPdf'
          link.href = pdf.output('datauristring')
          link.download = `${filename}.pdf`

          document.body.appendChild(link)
          document.getElementById('downloadPdf').click()

          // remove to make sure a new pdf will be downloaded and not the previous one
          const element = document.getElementById('downloadPdf')
          element.remove()
          this.makepdf = false
        })
      })
    },

    /*
    * Returns blob
    */
    getFileFromServer(url, callback) {
      const xhr = new XMLHttpRequest()
      // eslint-disable-next-line func-names
      xhr.onload = function () {
        const reader = new FileReader()
        // eslint-disable-next-line func-names
        reader.onloadend = function () {
          callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    },
  },
}
</script>
<style scoped>
.reportrange-text[data-v-1ebd09d2] {
  overflow: hidden;
  height: 37px;
  width: 10rem !important;
  }
</style>
  <style lang="scss" >
    @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>
  <style>
  .activation-btn {
    width: 100%;
    max-width: 16rem;
  }
  .slot {
    padding: 0.5rem;
    justify-content: space-between;
  }
  .slot span{
    margin-right:5px; margin-top:5px;
    font-size: 11px;
    font-weight: bold;
  }
  .mt-3 table tr th{font-size: 11px;}
  .mt-3 table tr td{font-size: 12px;}
  .mt-3 { margin-top: 0px !important;}
  .stat-picker{
    margin-right: 0.3rem;
    margin-bottom: -10px !important;
  }
  .btnPrint{
    margin-top: 5px;
    float: right;
    margin-right:5px;position: absolute;height: 32px;bottom:5px;left:60px;width:fit-content;
  }

  .btnDownload{
    margin-top: 5px;
    float: right;
    margin-right:10px;
  }

  .btnCSV{
    margin-top: 5px;
    float: right;
  }

  /* on phones make this */
  @media screen and (max-width: 500px) {
    div.adjustments{
      margin-top: 12px !important;
    }
    .btnPDF{
      margin-bottom: 3.2px;
      float: right;
    }
    .btnPrint{
    margin-top: 5px;
    float: right;
    margin-right:5px;position: absolute;height: 32px;bottom:5px;left:65px;width:fit-content;
  }
  }
  @media screen and (max-width: 350px) {
    .btnPrint{
    margin-top: 5px;
    float: right;
    margin-right:5px;position: absolute;height: 32px;bottom:5px;left:65px;width:fit-content;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 430px)  {
  [dir] .card-body{
    padding: 1rem;
  }
  div.revdetail div.vgt-global-search__input{
    height: 150px !important
  }
  .vgt-input{
    width: 220px !important;
  }
  .btnPrint{
    margin-top: 5px;
    margin-right:5px;position: absolute;height: 32px;bottom:-117px;left:-225px;width:275px;
  }
  .btnDownload{
    float:left;
    position:relative;
    width: 275px;
    margin-left: -235px;
    margin-top: 3px !important;
  }
  .btnCSV{
    float:left;
    position:relative;
    width: 275px;
    margin-left: -235px;
    margin-top: 3px !important;
  }
  .btnPrint{
    margin-top: 5px;
    float: right;
    margin-right:5px;position: absolute;height: 32px;bottom:5px;left:65px;width:fit-content;
  }
}

@media only screen and (max-width: 430px) {
[dir=ltr] .btnPrint {
  margin-right:5px;position: absolute;height: 32px;bottom:-117px;left:-235px;width: 275px;
}
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {
  [dir] .card-body{
    padding: 1rem;
  }
  div.revdetail div.vgt-global-search__input{
    height: 150px !important
  }
  .vgt-input{
    width: 200px !important;
  }
  .btnPrint{
    margin-top: 5px;
    float: right;
    margin-right:5px;position: absolute;height: 32px;bottom:-117px;left:-225px;width:275px;
  }
  .btnDownload{
    float:left;
    position:relative;
    width: 275px;
    margin-left: -225px;
    margin-top: 3px !important;
  }
  .btnCSV{
    float:left;
    position:relative;
    width: 275px;
    margin-left: -225px;
    margin-top: 3px !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {
  [dir] .card-body{
    padding: 1rem;
  }
  div.revdetail div.vgt-global-search__input{
    height: 150px !important
  }
  .vgt-input{
    width: 160px !important;
  }
  .btnPrint{
    margin-top: 5px;
    float: right;
    margin-right:5px;position: absolute;height: 32px;bottom:5px;left:-200px;width:255px;
  }
  .btnDownload{
    float:left;
    position:relative;
    width: 275px;
    margin-left: -200px;
    margin-top: 3px !important;
  }
  .btnCSV{
    float:left;
    position:relative;
    width: 275px;
    margin-left: -200px;
    margin-top: 3px !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {

}

/* smaller phone */
@media only screen and (max-width: 365px)  {
  [dir] .card-body{
    padding: 1rem;
  }
  div.revdetail div.vgt-global-search__input{
    height: 150px !important
  }
  .vgt-input{
    width: 150px !important;
  }
  .btnPrint{
    float:left;
    position:absolute;
    width: 230px;
    margin-left: -245px;
    margin-top: 107px !important;
  }
  .btnDownload{
    float:left;
    position:relative;
    width: 230px;
    margin-left: -180px;
    margin-top: 3px !important;
  }
  .btnCSV{
    float:left;
    position:relative;
    width: 230px;
    margin-left: -180px;
    margin-top: 3px !important;
  }
}
  .dark-layout .vgt-input{
    border: 1px solid #3f4455;
    background: transparent;
    color: #6f7583;
  }
  .dark-layout .vgt-input:focus{
    border-color: #fb50a1;
  }
  .dark-layout .reportrange-text{
    color: #c23f8d;
    border: 1px solid #c23f8d !important;
    background-color: transparent;
  }
  </style>
